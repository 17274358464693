body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Gecade";
  src: url(../../static/media/Gecade.7ee19e0e.ttf) format("truetype");
}

.each-slide > div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 350px;
}

.each-slide .slideShowBox {
  opacity: 0.6;
  padding: 20px;
  background: black;
  text-align: center;
  border-style: groove;
  border-radius: 25px;
}
.each-slide .slideShowBox span {
  color: gold;
  font-size: 20px;
  font-weight: bold;
  opacity: 1;
}

